import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import AppStoreLogo from './AppStoreLogo';
import PlayStoreLogo from './PlayStoreLogo';

class Jumbo extends React.Component<{}, {}> {
  private interval: any
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <Jumbotron>
        <h1>θ - Simple, Secure, Off-line</h1>
        <p>Backup your keys on paper or as PDF file.<br />
        </p>
      </Jumbotron>
    );
  }
}

export default Jumbo;