export const pdfTemplateHtml = `
<html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        background: linear-gradient(to bottom right, #f7f7f7, #e6e6e6);
        color: #333;
        margin: 0;
        padding: 20px;
      }
      .page {
        max-width: 800px;
        margin: auto;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      h2 {
        font-size: 24px;
        color: #0073e6;
        border-bottom: 2px solid #0073e6;
        padding-bottom: 10px;
      }
      small {
        display: block;
        margin-bottom: 20px;
        font-size: 14px;
        color: #555;
      }
      p {
        line-height: 1.6;
        margin-bottom: 20px;
      }
      .monospace {
        font-family: "Courier New", Courier, monospace;
        background: #f4f4f4;
        padding: 10px;
        border-radius: 4px;
      }
      img {
        max-width: 200px;
        display: block;
        margin-top: 10px;
      }
      .important {
        color: #e60000;
        font-weight: bold;
      }
    </style>
  </head>
  <body>
    <div class="page">
      <h2>Private Key Backup</h2>
      <small>This document (or 'paper wallet') contains wallet currency keys that are vital for accessing potentially valuable funds on blockchains like Bitcoin or Ethereum. Safeguard your funds carefully—<strong class="important">do not throw away this document!</strong></small>
      <hr/>
      <p>Keys stored on your mobile device or browser can be lost if they are not imported into a secure wallet. It is crucial to periodically clean your browser and ensure your keys are backed up properly. Failing to do so could result in permanent loss of access to your funds.</p>
      <p>This paper wallet is extremely safe, provided it is kept in a secure environment. Unlike digital storage, which is vulnerable to hacking or data loss, this physical backup is only at risk if it is physically compromised. Make sure to store it in a safe place where it cannot be lost, damaged, or accessed by unauthorized individuals.</p>
      <h3>Backup Details</h3>
      <p>This backup was created on {{ date }}</p>
      <h4>Notes</h4>
      <p>{{ notes }}</p>
      <h3>Private Key</h3>
      <p>Below you will find the private key in both text and QR code format.</p>
      <p><b>Text format</b></p>
      <p class="monospace">{{ keyData }}</p>
      <p><b>QR format</b></p>
      <img width="250px" height="250px" src="{{ qrDataUri }}" />
    </div>
  </body>
</html>
`;
